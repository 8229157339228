export const LogoIcon = ({
  color,
  height,
  width,
}: {
  color: string;
  height: number;
  width: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 80 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1343 56C9.53603 51.4111 2.88364 41.4271 2.88364 29.8495C2.88364 13.9236 15.4714 1.01308 30.9991 1.01308C35.5468 1.01308 39.8423 2.12048 43.6433 4.0867C39.3152 1.49179 34.25 0 28.8364 0C12.9105 0 0 12.9105 0 28.8364C0 41.3583 7.98132 52.0161 19.1343 56Z"
      fill={color}
    />
    <path
      d="M28.553 19.9713C28.4516 19.661 28.318 19.383 28.1521 19.1372C27.9893 18.8884 27.7942 18.6764 27.5669 18.5013C27.3426 18.3262 27.0876 18.1925 26.8019 18.1004C26.5162 18.0082 26.2029 17.9621 25.8619 17.9621C25.3028 17.9621 24.7943 18.1065 24.3366 18.3953C23.8789 18.6841 23.5148 19.1096 23.2445 19.6717C22.9741 20.2339 22.839 20.9236 22.839 21.7408C22.839 22.5579 22.9757 23.2476 23.2491 23.8098C23.5225 24.372 23.8927 24.7975 24.3596 25.0863C24.8266 25.375 25.3519 25.5194 25.9356 25.5194C26.4763 25.5194 26.9525 25.4042 27.3641 25.1738C27.7788 24.9403 28.1014 24.6116 28.3318 24.1877C28.5653 23.7607 28.682 23.2584 28.682 22.6808L29.0322 22.7546H26.1937V21.7408H29.788V22.7546C29.788 23.5318 29.6221 24.2076 29.2903 24.7821C28.9616 25.3566 28.5069 25.802 27.9263 26.1185C27.3488 26.4318 26.6852 26.5885 25.9356 26.5885C25.1 26.5885 24.3658 26.3919 23.7329 25.9987C23.1032 25.6054 22.6116 25.0463 22.2584 24.3213C21.9081 23.5963 21.733 22.7361 21.733 21.7408C21.733 20.9943 21.8329 20.323 22.0326 19.727C22.2353 19.128 22.521 18.618 22.8897 18.1972C23.2583 17.7763 23.6945 17.4537 24.1984 17.2295C24.7022 17.0052 25.2567 16.8931 25.8619 16.8931C26.3596 16.8931 26.8234 16.9683 27.2535 17.1189C27.6867 17.2663 28.0722 17.4768 28.4102 17.7502C28.7512 18.0205 29.0353 18.3446 29.2627 18.7225C29.49 19.0973 29.6467 19.5135 29.7327 19.9713H28.553Z"
      fill={color}
    />
    <path
      d="M35.6382 26.4595V17.0221H41.3338V18.0359H36.781V21.2247H41.0389V22.2385H36.781V25.4457H41.4075V26.4595H35.6382Z"
      fill={color}
    />
    <path
      d="M46.7704 18.0359V17.0221H53.8484V18.0359H50.8808V26.4595H49.738V18.0359H46.7704Z"
      fill={color}
    />
    <path
      d="M22.1017 40.8777V31.4403H23.2445V39.8639H27.6314V40.8777H22.1017Z"
      fill={color}
    />
    <path
      d="M39.4871 31.4403H40.6299V37.6889C40.6299 38.334 40.4778 38.91 40.1737 39.4169C39.8726 39.9207 39.4471 40.3186 38.8973 40.6104C38.3474 40.8992 37.7022 41.0436 36.9619 41.0436C36.2215 41.0436 35.5764 40.8992 35.0265 40.6104C34.4766 40.3186 34.0495 39.9207 33.7454 39.4169C33.4443 38.91 33.2938 38.334 33.2938 37.6889V31.4403H34.4366V37.5967C34.4366 38.0575 34.538 38.4676 34.7408 38.8271C34.9435 39.1834 35.2323 39.4645 35.6071 39.6703C35.9849 39.8731 36.4365 39.9745 36.9619 39.9745C37.4872 39.9745 37.9388 39.8731 38.3166 39.6703C38.6945 39.4645 38.9833 39.1834 39.183 38.8271C39.3857 38.4676 39.4871 38.0575 39.4871 37.5967V31.4403Z"
      fill={color}
    />
    <path
      d="M54.2885 31.4403V40.8777H53.1826L48.0399 33.4679H47.9478V40.8777H46.805V31.4403H47.9109L53.072 38.8685H53.1641V31.4403H54.2885Z"
      fill={color}
    />
    <path
      d="M60.8553 40.8777H59.6572L63.1224 31.4403H64.3021L67.7674 40.8777H66.5693L63.7491 32.9333H63.6754L60.8553 40.8777ZM61.2976 37.1912H66.1269V38.205H61.2976V37.1912Z"
      fill={color}
    />
    <path
      d="M73.1303 40.8777V31.4403H76.3191C77.0564 31.4403 77.6616 31.5663 78.1347 31.8182C78.6078 32.067 78.958 32.4095 79.1853 32.8458C79.4127 33.282 79.5263 33.7781 79.5263 34.3342C79.5263 34.8902 79.4127 35.3833 79.1853 35.8134C78.958 36.2435 78.6093 36.5814 78.1393 36.8272C77.6693 37.0699 77.0687 37.1912 76.3375 37.1912H73.757V36.159H76.3007C76.8045 36.159 77.21 36.0853 77.5172 35.9378C77.8275 35.7903 78.0517 35.5814 78.19 35.3111C78.3313 35.0377 78.402 34.712 78.402 34.3342C78.402 33.9563 78.3313 33.6261 78.19 33.3434C78.0487 33.0608 77.8229 32.8427 77.5126 32.6891C77.2023 32.5324 76.7922 32.4541 76.2822 32.4541H74.2731V40.8777H73.1303ZM77.5725 36.6382L79.895 40.8777H78.5678L76.2822 36.6382H77.5725Z"
      fill={color}
    />
  </svg>
);
