import { useBreakpointValue } from '@chakra-ui/react';
import { Link } from '@remix-run/react';
import type { FC } from 'react';
import { LogoIcon } from '~/assets/icons/logo';

interface LogoProps {
  inverted?: boolean;
}

export const Logo: FC<LogoProps> = ({ inverted = false }) => {
  const width = useBreakpointValue({ base: 60, md: 80 })!;
  const height = useBreakpointValue({ base: 40, md: 56 })!;
  return (
    <Link to="/">
      <LogoIcon
        color={inverted ? 'black' : 'white'}
        width={width}
        height={height}
      />
    </Link>
  );
};
